export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCw74m-xaIsx08NzGFZ1j41VWXe6RUEFuY',
    authDomain: 'umanni-feedback.firebaseapp.com',
    databaseURL: 'https://umanni-feedback.firebaseio.com',
    projectId: 'umanni-feedback',
    storageBucket: 'umanni-feedback.appspot.com',
    messagingSenderId: '142546785612'
  }
};
