import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { Mood } from '../modules/moods/models/mood';

@Injectable()
export class FirestoreService {

  constructor(public store: AngularFirestore, private auth: AuthService) { }

  moods(queryFn?: QueryFn) {
    return this.store.collection<Mood>('moods', queryFn)
  }
}
