import { Injectable } from '@angular/core';
import { RefService } from './ref.service';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { Modules } from '../models/modules';
import { AngularFireDatabase } from '@angular/fire/database';
import { Radar } from '../models/radar';
import { SentimentValue } from '../models/sentimentValue';
import { UsersEngagement } from '../models/usersEngagement';
declare let echarts: any;

@Injectable()
export class DbService {

  constructor(public db: AngularFireDatabase,
              public ref: RefService,
              public auth: AuthService) {}

  get baseRef() {
    return this.db.database.ref()
  }

  modulesObj() {
    return this.db.object<Modules>(this.ref.modulesRef).valueChanges()
  }

  get settingsUserImport() {
    return this.db.object(this.ref.settingsImportUsers).valueChanges()
  }
  get settingsMultiCampaings() {
    return this.db.object<Boolean>(this.ref.settingsMultiCampaings).valueChanges()
  }
  get blurOffensiveMaterialObj() {
    return this.db.object(this.ref.blurOffensiveMaterialRef).valueChanges()
    .pipe(map(enableSanitization => {
      if (enableSanitization == '0') {
        return false
      }
      return true
    }))
  }
  get enableSanitizationObj() {
    return this.db.object(this.ref.enableSanitizationRef).valueChanges()
    .pipe(map(enableSanitization => {
      if (enableSanitization == '0') {
        return false
      }
      return true
    }))
  }
  get logoObj() {
    return this.db.object(this.ref.logoRef).valueChanges()
  }

  get usersList() {
    return this.db.list<User>(this.ref.usersRef).valueChanges()
  }

  userToken(key) {
    return this.db.object(this.ref.usersTokensRef.child(key)).valueChanges()
  }
  get usersCount() {
    return this.db.list(this.ref.usersRef).valueChanges()
    .pipe(map(count => {return count.length ? null : 0}))
  }
  get user() {
    return this.db.object<User>(this.ref.userRef(this.auth.getUid())).valueChanges()
  }
  userObj(user: string) {
    return this.db.object<User>(this.ref.userRef(user)).valueChanges()
  }
  userStatusObj(userId: string) {
    return this.db.object<Boolean>(this.ref.userStatusRef(userId)).valueChanges()
  }
  userNameObj(user: string) {
    return this.db.object(this.ref.userRef(user).child('userName')).valueChanges()
  }

  get_date(createdAt) {
    const d = new Date(createdAt);
    const weekday = new Array(7);
    weekday[0] =  'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';
    return d.toLocaleString()
  }
  get_month(mes) {
    const month = new Array();
    month[0] = 'January';
    month[1] = 'February';
    month[2] = 'March';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'June';
    month[6] = 'July';
    month[7] = 'August';
    month[8] = 'September';
    month[9] = 'October';
    month[10] = 'November';
    month[11] = 'December';
    const n = month[mes];
    return n
  }

  get getTimestamp() {
    return new Date().getTime()
  }
  get project() {
    return this.db.database.ref().toString()
  }
  get projectId() {
    return this.ref.baseRef.toString().substring(8).split('.')[0]
  }
  get projectName() {
    switch (this.projectId) {
      case 'umanni-feedback': return 'Staging'
      case 'feedback-ed5aa': return 'Insights'
      case 'presentation-insights': return 'Demo'
      case 'insights-indusval': return 'TalkLab'
      case 'algar-insights': return 'Insights Performance'
      case 'smiles-insights': return 'Smiles Insights'
      case 'zeiss-insights': return 'Zeiss Insights'
      case 'omni-insights': return 'Omni Insights'
      case 'pra-valer-insights': return 'You Up'
      case 'fiesc-insights': return 'Gestão de Desempenho'
      case 'ambev-insights': return 'Ambev'
    }
    return this.projectId
  }

  get contextsList() {
    return this.db.list(this.ref.contextsRef, ref => ref.orderByChild('active').equalTo(true)).valueChanges()
  }

  get statisticsFeedbacks() {
    return this.baseRef.child('feedbacks');
  }
  get statisticsFeedbacksContextAverageSentimentValue() {
    return this.statisticsFeedbacks.child('contextAverageSentimentValue');
  }
  get statisticsFeedbacksContextCount() {
    return this.statisticsFeedbacks.child('contextCount');
  }
  get statisticsFeedbacksTagAverageSentimentValue() {
    return this.statisticsFeedbacks.child('tagAverageSentimentValue');
  }
  get statisticsFeedbacksTagCount() {
    return this.statisticsFeedbacks.child('tagCount');
  }
  statisticsFeedbacksMonthAverageSentimentValue(year, month) {
    return this.statisticsFeedbacks.child('monthAverageSentimentValue').child(year).child(month);
  }
  statisticsFeedbacksMonthTotalCount(year, month) {
    return this.statisticsFeedbacks.child('monthTotalCount').child(year).child(month);
  }
  statisticsFeedbacksYearMonthlyAverageSentimentValue(year) {
    return this.statisticsFeedbacks.child('yearMonthlyAverageSentimentValue').child(year);
  }
  statisticsFeedbacksYearMonthlyTotalCount(year) {
    return this.statisticsFeedbacks.child('yearMonthlyTotalCount').child(year);
  }
  statisticsUsersFeedbacksSentimentValueReceivedObj(uid) {
    return this.db.object(this.ref.statisticsUsersFeedbacksReceiver(uid).child('sentimentValue')).valueChanges()
  }
  statisticsUsersFeedbacksSentimentValueSentObj(uid) {
    return this.db.object(this.ref.statisticsUsersFeedbacksSent(uid).child('sentimentValue')).valueChanges()
  }
  moodsList(uid) {
    return this.db.list(this.ref.moodsList(uid)).valueChanges()
  }
  contextAverageSentimentValueFeedbacks() {
    // return this.db.list(this.ref.contextsRef).snapshotChanges()
    return this.db.list(this.ref.statisticsFeedbacksContextAverageSentimentValue).snapshotChanges()
    .pipe(map(contexts => {
      const data = []

      for (const context of contexts) {
        this.ref.contextsRef.child(context.key).once('value', snap => {
          const value = context.payload.val() || 0
          data.push({
            title: snap.child('name').val() || 'Desconhecido',
            value: (Number(value) * 100).toFixed(2) + '%' || '0.0%',
            // title: context.payload.child('name').val(),
            // value: (Number(snap.val())*100).toFixed(2)+'%' || '0.0%',
            increase: true,
            option: {
              grid: {
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                containLabel: false
              },
              xAxis: [{
                type: 'category',
                show: false,
              }],
              yAxis: [{
                type: 'value',
                min: 80,
                max: 200,
                show: false
              }],
              series: [{
                name: 'Business A',
                type: 'line',
                showSymbol: false,
                smooth: true,
                lineStyle: {
                  normal: {
                    width: 2.5,
                    color: '#03a9f4',
                    shadowBlur: 7,
                    shadowColor: 'rgba(38, 183, 239, 0.5)',
                    shadowOffsetY: 12
                  }
                },
                data: [130, 150, 100, 115, 100 ]
              }]
            }
          })
        })
      }
      return data
    }))
  }
  contextAverageSentimentValueFeedbacksRadar() {
    return this.db.list(this.ref.contextsRef).snapshotChanges()
    .pipe(map(contexts => {
        const indicadores = []
        for (const context of contexts) {
          indicadores.push({text: context.payload.child('name').val(), max: 1, id: context.key})
        }
      return indicadores
    }))
  }
  tagAverageSentimentValueFeedbacksRadar() {
    return this.db.list(this.ref.competenciesRef).snapshotChanges()
    .pipe(map(competencies => {

      const indicadores = []
      for (const competence of competencies) {
        indicadores.push({text: competence.payload.child('name').val(), max: 1, id: competence.key})

      }
      return indicadores
    }))
  }
  monthTotalCountMessages(year, month) {
    return this.db.object<Array<Number>>(this.ref.statisticsMessagesMonthTotalCount(year, month)).valueChanges()
    .pipe(map(messages => {
      const calendar = []
      const values = []

      for (let i = 0; i < messages.length ? null : 0; i++) {
        calendar.push(i + 1)
        if (messages[i] == undefined) {
          values[i] = 0
        } else {
          values[i] = messages[i]
        }
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: calendar
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Moods',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }
  monthAverageSentimentValueMoods(year, month) {
    return this.db.object<Array<Number>>(this.ref.statisticsMoodsMonthAverageSentimentValue(year, month - 1)).valueChanges()
    .pipe(map(moods => {
      const calendar = []
      const values = []

      for (let i = 0; i < moods.length ? null : 0; i++) {
        calendar.push(i + 1)
        if (moods[i] == undefined) {
          values[i] = 0
        } else {
          values[i] = moods[i]
        }
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: calendar
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Moods',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }

  tagAverageSentimentValueMessagesRadar() {
    return this.db.object<Object>(this.ref.statisticsMessagesTagAverageSentimentValue).snapshotChanges()
    .pipe(map(contexts => {
      const values = []
      const labels = []
      contexts.payload.forEach(context => {
        this.ref.messagesRecipientsRef.child(context.key).child('name').on('value', (snap) => {
          labels.push(snap.val())
          values.push(context.val())
        })
        return false
      })
      // for(let context of this.contexts){
      //   if(contexts[context['key']] == undefined){
      //     values.push(0)
      //   }else{
      //     values.push(contexts[context['key']])
      //   }
      // }
      const radar = {
        tooltip: {},
        legend: {
          data: [this.project],
          textStyle: {
            color: '#1886C4'
          }
        },
        radar: {
          indicator: labels
        },
        series: [{
          name: 'Sentiment Value',
          type: 'radar',
          data : [
            {
              value : values,
              name : 'Sentiment Value',
              color: '#D824CB'
            }
          ],
          itemStyle: {
            normal: {
              color: (val) => val.data.color
            }
          },
        }]
      };
      return radar
    }))
  }
  monthTotalCountMoods(year, month) {
    return this.db.object<Array<Number>>(this.ref.statisticsMoodsMonthTotalCount(year, month - 1)).valueChanges()
    .pipe(map(moods => {
      const calendar = []
      const values = []

      for (let i = 0; i < moods.length ? null : 0; i++) {
        calendar.push(i + 1)
        if (moods[i] == undefined) {
          values[i] = 0
        } else {
          values[i] = moods[i]
        }
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: calendar
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Moods',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }
  last15MoodsBySentimentValue() {
    return this.db.list(this.ref.anonMoodsList, ref => ref.orderByKey().limitToFirst(15)).valueChanges()
    .pipe(map(feedbacks => {
      const values = []
      const labels = []
      for (const feedback of feedbacks) {
        values.push(Number(feedback['value']))
        labels.push(feedback['textMood'])
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: labels
          // data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Sentiment Value',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }
  yearMonthlyTotalCountMoods(year) {
    return this.db.object(this.ref.statisticsMoodsYearMonthlyTotalCount(year)).valueChanges()
    .pipe(map(moods => {
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Clima',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: moods ? null : 0
        }]
      };
      return data
    }))
  }
  yearMonthlyAverageSentimentValueMoods(year) {
    return this.db.object(this.ref.statisticsMoodsYearMonthlyAverageSentimentValue(year)).valueChanges()
    .pipe(map(moods => {
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'FEEDBACKS',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: moods
        }]
      };
      return data
    }))
  }
  monthAverageSentimentValueMessages(year, month) {
    return this.db.object<Array<Number>>(this.ref.statisticsMessagesMonthAverageSentimentValue(year, month)).valueChanges()
    .pipe(map(messages => {
      const calendar = []
      const values = []

      for (let i = 0; i < messages.length ? null : 0; i++) {
        calendar.push(i + 1)
        if (messages[i] == undefined) {
          values[i] = 0
        } else {
          values[i] = messages[i]
        }
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: calendar
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Moods',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }
  last15MessagesBySentimentValue() {
    return this.db.list(this.ref.anonMoodsList, ref => ref.orderByKey().limitToFirst(15)).valueChanges()
    .pipe(map(feedbacks => {
      const values = []
      const labels = []
      for (const feedback of feedbacks) {
        values.push(Number(feedback['value']))
        labels.push(feedback['textMood'])
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: labels
          // data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Sentiment Value',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }
  yearMonthlyTotalCountMessages(year) {
    return this.db.object(this.ref.statisticsMessagesYearMonthlyTotalCount(year)).valueChanges()
    .pipe(map(messages => {
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Messages',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: messages
        }]
      };
      return data
    }))
  }
  yearMonthlyAverageSentimentValueMessages(year) {
    return this.db.object(this.ref.statisticsMessagesYearMonthlyAverageSentimentValue(year)).valueChanges()
    .pipe(map(messages => {
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'Sentiment Value',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: messages
        }]
      };
      return data
    }))
  }
  contextCountFeedbacks() {
    return this.db.list(this.ref.statisticsFeedbacksContextCount).snapshotChanges()
    .pipe(map(contexts => {
      const data = []
      for (const context of contexts) {
          this.ref.contextsRef.child(context.key).once('value', snap => {
          data.push({
            title: snap.child('name').val() || 'Desconhecido',
            value: context.payload.val() || 0,
            increase: true,
            option: {
              grid: {
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                containLabel: false
              },
              xAxis: [{
                type: 'category',
                show: false,
              }],
              yAxis: [{
                type: 'value',
                min: 80,
                max: 200,
                show: false
              }],
              series: [{
                name: 'Business A',
                type: 'line',
                showSymbol: false,
                smooth: true,
                lineStyle: {
                  normal: {
                    width: 2.5,
                    color: '#03a9f4',
                    shadowBlur: 7,
                    shadowColor: 'rgba(38, 183, 239, 0.5)',
                    shadowOffsetY: 12
                  }
                },
                data: [130, 150, 100, 115, 100 ]
              }]
            }
          })
        })
      }
      return data
    }))
  }
  monthAverageSentimentValueFeedbacks(year, month) {
    return this.db.object<Array<Number>>(this.ref.statisticsFeedbacksMonthAverageSentimentValue(year, month - 1)).valueChanges()
    .pipe(map(feedbacks => {

      const calendar = []
      const values = []
      if (feedbacks != null) {
        for (let i = 0; i < feedbacks.length; i++) {
          calendar.push(i + 1)
          if (feedbacks[i] == undefined) {
            values[i] = 0
          } else {
            values[i] = feedbacks[i]
          }
        }
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: calendar
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'FEEDBACKS',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }
  monthTotalCountFeedbacks(year, month) {
    return this.db.object<Array<Number>>(this.ref.statisticsFeedbacksMonthTotalCount(year, month - 1)).valueChanges()
    .pipe(map(feedbacks => {

      const calendar = []
      const values = []

      if (feedbacks != null) {
        for (let i = 0; i < feedbacks.length ? null : 0; i++) {
          calendar.push(i + 1)
          if (feedbacks[i] == undefined) {
            values[i] = 0
          } else {
            values[i] = feedbacks[i]
          }
        }
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: calendar
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'FEEDBACKS',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }
  tagAverageSentimentValueFeedbacks() {
    return this.db.list(this.ref.statisticsFeedbacksTagAverageSentimentValue).snapshotChanges()
    .pipe(map(competencies => {
      const data = []

      for (const competence of competencies) {
        this.ref.competenciesRef.child(competence.key).once('value', snap => {
          const value = competence.payload.val() || 0
          data.push({
            title: snap.child('name').val() || 'Desconhecido',
            value: (Number(value) * 100).toFixed(2) + '%' || '0.0%',
            // title: competence.payload.child('name').val(),
            // value: (Number(snap.val())*100).toFixed(2)+'%' || '0.0%',
            increase: true,
            option: {
              grid: {
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                containLabel: false
              },
              xAxis: [{
                type: 'category',
                show: false,
              }],
              yAxis: [{
                type: 'value',
                min: 80,
                max: 200,
                show: false
              }],
              series: [{
                name: 'Business A',
                type: 'line',
                showSymbol: false,
                smooth: true,
                lineStyle: {
                  normal: {
                    width: 2.5,
                    color: '#03a9f4',
                    shadowBlur: 7,
                    shadowColor: 'rgba(38, 183, 239, 0.5)',
                    shadowOffsetY: 12
                  }
                },
                data: [130, 150, 100, 115, 100 ]
              }]
            }
          })
        })
      }
      return data
    }))
  }
  tagCountFeedbacks() {
    // return this.db.list(this.ref.competenciesRef).snapshotChanges()
    return this.db.list(this.ref.statisticsFeedbacksTagCount).snapshotChanges()
    .pipe(map(competencies => {
      const data = []

      for (const competence of competencies) {
        this.ref.competenciesRef.child(competence.key).once('value', snap => {
          data.push({
            // title: competence.payload.child('name').val(),
            // value: snap.val() || 0,
            title: snap.child('name').val() || 'Desconhecido',
            value: competence.payload.val() || 0,
            increase: true,
            option: {
              grid: {
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                containLabel: false
              },
              xAxis: [{
                type: 'category',
                show: false,
              }],
              yAxis: [{
                type: 'value',
                min: 80,
                max: 200,
                show: false
              }],
              series: [{
                name: 'Business A',
                type: 'line',
                showSymbol: false,
                smooth: true,
                lineStyle: {
                  normal: {
                    width: 2.5,
                    color: '#03a9f4',
                    shadowBlur: 7,
                    shadowColor: 'rgba(38, 183, 239, 0.5)',
                    shadowOffsetY: 12
                  }
                },
                data: [130, 150, 100, 115, 100 ]
              }]
            }
          })
        })
      }
      return data
    }))
  }
  yearMonthlyAverageSentimentValueFeedbacks(year) {
    return this.db.object(this.ref.statisticsFeedbacksYearMonthlyAverageSentimentValue(year)).valueChanges()
    .pipe(map(feedbacks => {
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'FEEDBACKS',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: feedbacks
        }]
      };
      return data
    }))
  }
  yearMonthlyTotalCountFeedbacks(year) {
    return this.db.object(this.ref.statisticsFeedbacksYearMonthlyTotalCount(year)).valueChanges()
    .pipe(map(feedbacks => {
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'FEEDBACKS',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: feedbacks
        }]
      };
      return data
    }))
  }
  last15FeedbacksBySentimentValue() {
    return this.db.list(this.ref.feedbacksList, ref => ref.orderByKey().limitToLast(15)).valueChanges()
    .pipe(map(feedbacks => {
      const values = []
      const labels = []
      for (const feedback of feedbacks) {
        values.push(Number(feedback['sentimentValue']))
        labels.push(feedback['text'])
      }
      const data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#d9dadc',
              type: 'dashed',
              shadowOffsetX: '-1',
              shadowColor: 'rgba(217, 218, 220, 0.5)'
            }
          }
        },
        grid: {
          top: '10%',
          left: '0',
          right: '20px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            margin: 15,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          data: labels
          // data: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', '']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 24,
            textStyle: {
              color: '#a6a5a6',
              fontFamily: 'Roboto',
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#212121',
              opacity: 0.2
            }
          }
        }],
        series: [{
          name: 'FEEDBACKS',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 3,
          showSymbol: true,
          lineStyle: {
            normal: {
              width: 0
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3e9ace'
              }, {
                offset: 1,
                color: '#9dcce6'
              }], false),
              opacity: 0.73
            }
          },
          itemStyle: {
            normal: {
              color: '#ffffff',
              borderColor: '#3e9ace',
              borderWidth: 2
            }
          },
          data: values
        }]
      };
      return data
    }))
  }

  statisticsFeedbacksUsersReceivedCompetencies(userId: string) {
    return this.db.list<Radar>(`/statistics/feedbacks/users/${userId}/received/competencies`)
  }
  statisticsFeedbacksUsersSentCompetencies(userId: string) {
    return this.db.list<Radar>(`/statistics/feedbacks/users/${userId}/sent/competencies`)
  }
  statisticsFeedbacksUsersReceivedContexts(userId: string) {
    return this.db.list<Radar>(`/statistics/feedbacks/users/${userId}/received/contexts`)
  }
  statisticsFeedbacksUsersSentContexts(userId: string) {
    return this.db.list<Radar>(`/statistics/feedbacks/users/${userId}/sent/contexts`)
  }
  statisticsFeedbacksUsersReceivedSentimentValue(userId: string) {
    return this.db.object<SentimentValue>(`/statistics/feedbacks/users/${userId}/received/sentimentValue`)
  }
  usersEngagement() {
    return this.db.object<UsersEngagement>(`v3/settings/users-engagement`)
  }
}
