import { Component, OnInit, Input } from '@angular/core';
import { Mood } from '../../../../modules/moods/models/mood';

@Component({
  selector: 'app-chart-moods',
  templateUrl: './chart-moods.component.html',
  styleUrls: ['./chart-moods.component.scss']
})
export class ChartMoodsComponent implements OnInit {

  @Input() moods: Mood[]
  chart
  @Input() isCard = true
  constructor() { }

  ngOnInit() {
    if (this.moods.length != 0) {
      const label = []
      const data = []
      for (const mood of this.moods) {
        label.push(mood.text)
        data.push(mood.sentimentValue)
      }
      this.chart = {
      toolbox: {
        show : true,
        feature : {
          saveAsImage : {
            show : true,
            title : 'save',
            type : 'png',
          }
        }
      },
      calculable : true,
      xAxis : [
        {
          type : 'category',
          boundaryGap : false,
          data : label
        }
      ],
      yAxis : [
        {
          type : 'value'
        }
      ],
      series : [
          {
            type: 'line',
            smooth: true,
            itemStyle: {normal: {areaStyle: {type: 'default'}}},
            data: data
          }
      ]
      }
    }
  }
}
