import { Component, OnInit, HostBinding } from '@angular/core';
import { routerAnimation } from '../../../utils/page.animation';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { DbService } from '../../../services/db.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-login-forgot-password',
  templateUrl: './login-forgot-password.component.html',
  styleUrls: ['./login-forgot-password.component.scss'],
  animations: [routerAnimation]
})
export class LoginForgotPasswordComponent {

  @HostBinding('@routerAnimation') routerAnimation = true;

  logo = `/assets/logo/${this.db.projectId}/logo.png`

  constructor(public auth: AuthService,
              public router: Router,
              public db: DbService,
              private snack: MatSnackBar) {}

  reset(email) {
    this.auth.resetPassword(email)
    .then(() => {
      this.snack.open('Email de reset de senha enviado com sucesso!', null, {duration: 4000})
    })
    .catch((error) => {
      this.snack.open('Ocorreu um erro tente mais tarde.', null, {duration: 2000})
    })
  }
}
