import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from '../../../services/db.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login-link',
  templateUrl: './login-link.component.html',
  styleUrls: ['./login-link.component.scss']
})
export class LoginLinkComponent implements OnInit {

  constructor(private auth: AuthService,
              private db: DbService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    console.log(this.activatedRoute.snapshot.params.email)
    if (this.auth.af.auth.isSignInWithEmailLink(window.location.href)) {
      let email = this.activatedRoute.snapshot.params.email
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      this.auth.af.auth.signInWithEmailLink(email, window.location.href)
        .then((result) => {
          this.router.navigate(['/login'])
          this.db.userObj(this.auth.getUid()).pipe(take(1)).subscribe(user => {
            if (user.role != 'admin') {
              this.router.navigateByUrl('/');
            } else {
              this.router.navigateByUrl('/admin');
            }
          })
        })
        .catch((error) => {

        });
    }
  }
}
