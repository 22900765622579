import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routerAnimation } from '../../utils/page.animation';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../../services/auth.service';
import { DbService } from '../../services/db.service';
import { map, take } from 'rxjs/operators';
import { AlertsService } from '../../modules/alerts/services/alerts.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerAnimation]
})
export class LoginComponent implements OnInit {

  @HostBinding('@routerAnimation') routerAnimation = true;
  logo = `/assets/logo/${this.db.projectId}/logo.png`

  constructor(public auth: AuthService, public router: Router, public db: DbService, private snack: MatSnackBar, private alert: AlertsService) {}

  ngOnInit() {
    this.auth.af.authState.subscribe(user => {
      if (user) {
        this.db.userObj(this.auth.getUid()).pipe(take(1)).subscribe(user => {
          if (user.role != 'admin') {
            this.router.navigateByUrl('/');
          } else {
            this.router.navigateByUrl('/admin');
          }
        })
      }
    })
  }

  async login(login, password: string) {
    const loading = this.alert.loading('Carregando...')
    try {
      await this.auth.emailLogin(login, password.trim())
      loading.close()
    } catch (error) {
      loading.close()
      switch (error.code) {
        case 'auth/user-not-found':
        this.alert.open('Ocorreu um erro!', 'Não há registro de usuário correspondente a este email.')
        return
        case 'auth/invalid-email':
        this.alert.open('Ocorreu um erro!', 'O endereço de e-mail está mal formatado.')
        return
        case 'auth/wrong-password':
        this.alert.open('Ocorreu um erro!', 'A senha é inválida.')
        return
        default: this.alert.open('Ocorreu um erro!', error)
      }
    }
  }
}
