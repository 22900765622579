import { Injectable } from '@angular/core'
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({ providedIn: 'root' })
export class FunctionsService {

  constructor(private fns: AngularFireFunctions) {}

  claims() {
    return this.fns.httpsCallable('users-claims')
  }
}
