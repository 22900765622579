import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DbService } from '../services/db.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../utils/AuthenticationService';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private db: DbService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = await this.db.db.object(`users/${this.auth.currentUser().uid}`).valueChanges().pipe(first()).toPromise()
        if (currentUser['role'] == 'admin') {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
