import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Data } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Claims } from '../models/claims';
@Injectable()
export class AuthService implements Data {

  authState: any = null;

  constructor(public af: AngularFireAuth) {

    this.af.authState.subscribe((auth) => {
      this.authState = auth
    });

  }

  emailLogin(email: string, password: string) {
    return this.af.auth.signInWithEmailAndPassword(email, password)
  }

  getUid(): string {
    return this.af.auth.currentUser.uid
  }

  resetPassword(email: string) {
    return this.af.auth.sendPasswordResetEmail(email)
      .then(() => console.log('email sent'))
      .catch((error) => console.log(error))
  }

  logout() {
    return this.af.auth.signOut()
  }

  get claims(): Observable<Claims> {
    return this.af.idTokenResult
    .pipe(map(value => {
      return value.claims
    }))
  }
}
