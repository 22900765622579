import { Component, OnInit } from '@angular/core';
import { ResizeService } from '../../../services/resize.service';
import { DbService } from '../../../services/db.service';
import { AuthService } from '../../../services/auth.service';
import { Observable } from 'rxjs';
import { Radar } from '../../../models/radar';
import { SentimentValue } from '../../../models/sentimentValue';
import { FirestoreService } from '../../../services/firestore.service';
declare let echarts: any;

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})

export class PerformanceComponent implements OnInit {

  option
  radar
  moods
  date
  chartInstances = [];
  resizeSubscription;
  statisticsFeedbacksUsers: Observable<Radar[]>;
  statisticsFeedbacksUsersSentimentValue;

  constructor(private firestore: FirestoreService, private db: DbService, private auth: AuthService, private resizeService: ResizeService) {
    this.resizeSubscription = resizeService.resizeInformer$.subscribe(
      () => this.chartInstances.forEach((chart) => chart.resize())
    );
  }

  ngOnInit(): void {
      this.date = new Date()
      this.statisticsFeedbacksUsers = this.db.statisticsFeedbacksUsersReceivedContexts(this.auth.getUid()).valueChanges()
      if (this.db.projectId === 'smiles-insights') {
        this.statisticsFeedbacksUsers = this.db.statisticsFeedbacksUsersReceivedCompetencies(this.auth.getUid()).valueChanges()
      }
      this.statisticsFeedbacksUsersSentimentValue = this.db.statisticsFeedbacksUsersReceivedSentimentValue(this.auth.getUid()).valueChanges()
      this.statisticsFeedbacksUsers.subscribe(competencies => {
        if (competencies.length > 0) {
            const indicadores = []
            const values = []
            competencies.forEach(competence => {
              indicadores.push({text: competence.name, max: 1})
              values.push(competence.score)
              return false
            })
            this.radar = {
              title : {
                  text: '',
                  subtext: ''
              },
              tooltip : {
                  trigger: 'axis'
              },
              legend: {
                  orient : 'vertical',
                  x : 'right',
                  y : 'bottom',
                //   data:['预算分配（Allocated Budget）','实际开销（Actual Spending）']
              },
              toolbox: {
                  show : true,
                  feature : {
                    saveAsImage : {
                        show : true,
                        title : 'save',
                        type : 'png',
                        // lang : ['点击保存']
                    }
                  }
              },
              radar : [
                  {
                  radius: 120,
                  indicator : indicadores
                  }
              ],
              calculable : true,
              series : [
                {
                  name: '',
                  type: 'radar',
                  itemStyle: {
                  normal: {
                    // areaStyle: {
                    //     type: 'default'
                    // }
                    areaStyle: {
                      normal: {
                          opacity: 0.9,
                          color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                              {
                                  color: '#B8D3E4',
                                  offset: 0
                              },
                              {
                                  color: '#72ACD1',
                                  offset: 1
                              }
                          ])
                      }
                    }
                  }
                },
                data : [
                  {
                    value : values,
                    name : ''
                  },
                ]
              }
            ]
          }
        }
      })
      this.statisticsFeedbacksUsersSentimentValue.subscribe(sentimentValue => {
        if (sentimentValue) {
          this.option = {
            // title : {
            //     text: '南丁格尔玫瑰图',
            //     x:'center'
            // },
            color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
            tooltip : {
                trigger: 'item',
                // formatter: "{a} <br/>{b} : {c} ({d}%)"
                formatter: '{b} : {c} ({d}%)'
            },
            toolbox: {
                show : true,
                feature : {
                    mark : {show: true},
                    // dataView : {show: true, readOnly: false},
                    magicType : {
                        show: true,
                        type: ['pie', 'funnel']
                    },
                    // restore : {show: true},
                    // saveAsImage : {show: true}
                    saveAsImage : {
                      show : true,
                      title : 'save',
                      type : 'png',
                      // lang : ['点击保存']
                  }
                }
            },
            calculable : true,
            series : [
                {
                    name: '增值电信业务统计表',
                    type: 'pie',
                    radius : [40, 150],
                    roseType : 'area',
                    data: [
                        {value: sentimentValue.awsomeCount, name: 'Ótimo'},
                        {value: sentimentValue.goodCount, name: 'Bom'},
                        {value: sentimentValue.averageCount, name: 'Regular'},
                        {value: sentimentValue.badCount, name: 'Ruim'},
                        {value: sentimentValue.worstCount, name: 'Péssimo'}
                    ].sort(function (a, b) { return a.value - b.value; })
                }
            ]
        };
        }
      })
      this.moods = this.firestore.moods().valueChanges()
      // this.db.moodsList(this.auth.getUid()).subscribe(moods => {
      //   if(moods.length != 0){
      //     let label = []
      //     let data = []
      //     for(let mood of moods){
      //       label.push(mood['textMood'])
      //       data.push(mood['value'])
      //     }
      //     this.moods = {
      //     toolbox: {
      //       show : true,
      //       feature : {
      //         saveAsImage : {
      //           show : true,
      //           title : 'save',
      //           type : 'png',
      //         }
      //       }
      //     },
      //     calculable : true,
      //     xAxis : [
      //       {
      //         type : 'category',
      //         boundaryGap : false,
      //         data : label
      //       }
      //     ],
      //     yAxis : [
      //       {
      //         type : 'value'
      //       }
      //     ],
      //     series : [
      //         {
      //           type:'line',
      //           smooth:true,
      //           itemStyle: {normal: {areaStyle: {type: 'default'}}},
      //           data: data
      //         }
      //     ]
      //     }
      //   }
      // })
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }
}
