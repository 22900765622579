import { TranslateService, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
  ],
  exports: [
      TranslateModule
  ]
})

export class TranslateSharedModule {
  constructor(translate: TranslateService) {
    switch (translate.getBrowserLang()) {
      case 'es': return translate.use('es');
      case 'pt': return translate.use('pt');
      default: return translate.use('en')
    }
  }
}
