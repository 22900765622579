import { Component, OnInit, HostListener, HostBinding, ViewChild } from '@angular/core';
import { ResizeService } from '../../../services/resize.service';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material';
import { routerAnimation } from '../../../utils/page.animation';
import { Modules } from '../../../models/modules';
import { AuthService } from '../../../services/auth.service';
import { DbService } from '../../../services/db.service';
import { Observable } from 'rxjs';
import { User } from '../../../models/user';

@Component({
  selector: 'app-main-admin',
  templateUrl: './main-admin.component.html',
  styleUrls: ['./main-admin.component.scss'],
  animations: [routerAnimation]
})
export class MainAdminComponent implements OnInit {

  feedbacksTotalCount = 0
  notificationsTotalCount = 0
  campaignsTotalCount = 0
  moodsTotalCount = 0
  usersCount = 0
  @ViewChild('comments') sidenavComments: MatSidenav;
  @HostBinding('@routerAnimation') routerAnimation = true;
  @HostBinding('class.dark-theme') darkTheme = false;
  _sidenavMode = 'side';
  _boxedLayout = false;
  sideNavOpened = false;
  heightView = window.innerHeight - 50;
  photo = 'assets/avatars/4040.png'
  email = 'Carregando...'
  feedbackComments = null
  name = 'Insights'
  modules: Observable<Modules>
  user: Observable<User>

  constructor(public resizeService: ResizeService, public auth: AuthService, public db: DbService, public router: Router) {
    this.auth.af.authState.subscribe(user => {
      if (!user) { return this.router.navigate(['login']) }
      this.user = db.user
      this.modules = db.modulesObj()
      this.name = db.projectName
      document.querySelector('body').classList.add(this.db.projectId);
    })
  }

  logout() {
    this.auth.logout()
  }

  close() {
    this.feedbackComments = null;
    this.sidenavComments.close();
  }

  ngOnInit() {
    this.onResize();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
    setTimeout(() => this.sideNavOpened = true, 0);
  }
  set sidenavMode(val) {
    this._sidenavMode = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }
  get sidenavMode() {
    return this._sidenavMode;
  }
  set boxedLayout(val) {
    this._boxedLayout = val;
    setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }
  get boxedLayout() {
    return this._boxedLayout;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.heightView = window.innerHeight - 50
    if (window.innerWidth < 800) {
      this.sideNavOpened = false;
      this._sidenavMode = 'over';
    }
  }
}
