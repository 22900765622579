import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginForgotPasswordComponent } from './pages/login/login-forgot-password/login-forgot-password.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { MainUserComponent } from './pages/main/main-user/main-user.component';
import { PerformanceComponent } from './pages/performance/performance/performance.component';
import { MainAdminComponent } from './pages/main/main-admin/main-admin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginLinkComponent } from './pages/login/login-link/login-link.component';
import { AuthGuard } from './guards/auth.guard';

export const ROUTES: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'login/email/:email', component: LoginLinkComponent},
  { path: 'forgot/password', component: LoginForgotPasswordComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: '', component: MainUserComponent, children: [
    {path: '', redirectTo: '/performance', pathMatch: 'full'},
    {path: 'performance', component: PerformanceComponent},
    {path: 'feedbacks', loadChildren: './modules/feedbacks/feedbacks.module#FeedbacksModule'},
    {path: 'moods', loadChildren: './modules/moods/moods.module#MoodsModule'},
    {path: 'messages', loadChildren: './modules/messages/messages.module#MessagesModule'},
    {path: 'campaigns', loadChildren: './modules/campaigns/campaigns.module#CampaignsModule'},
    {path: 'groups', loadChildren: './modules/groups/groups.module#GroupsModule'},
    {path: 'tickets', loadChildren: './modules/tickets/tickets.module#TicketsModule'},
    {path: 'careers', loadChildren: './modules/careers/careers.module#CareersModule'},
  ]},
  { path: 'admin', component: MainAdminComponent, canActivate: [AuthGuard], children: [
    {path: '', redirectTo: '/admin/dashboard', pathMatch: 'full'},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'notifications', loadChildren: './modules/notifications/notifications.module#NotificationsModule'},
    {path: 'feedbacks', loadChildren: './modules/feedbacks/feedbacks.module#FeedbacksModule'},
    {path: 'moods', loadChildren: './modules/moods/moods.module#MoodsModule'},
    {path: 'messages', loadChildren: './modules/messages/messages.module#MessagesModule'},
    {path: 'users', loadChildren: './modules/users/users.module#UsersModule'},
    {path: 'groups', loadChildren: './modules/groups/groups.module#GroupsModule'},
    {path: 'campaigns', loadChildren: './modules/campaigns/campaigns.module#CampaignsModule'},
    {path: 'settings', loadChildren: './modules/settings/settings.module#SettingsModule'},
    {path: 'tickets', loadChildren: './modules/tickets/tickets.module#TicketsModule'},
    {path: 'careers', loadChildren: './modules/careers/careers.module#CareersModule'},
  ]},
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
]
